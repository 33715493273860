import React from 'react'
import Banner from '../../Components/reusable/Banner'
import Layout from '../../Components/reusable/Layout'
import MyCategory from "../../Components/product/category"
function SubCategory() {
    return (
        <Layout>
            <Banner title="Spline Shafts" />
<section className="section my-5 py-5">
<div className="container">
    <div className="row my-5">
 
 
        <MyCategory
        title="AUTOMOTIVE SPLINE SHAFT"
        content="We have carved a niche among the chief Automotive Spline Shaft Manufacturers and Suppliers from India, catering to the varied needs of the Automotive Industry.
         We provide the Automotive Spline Shaft as per the drawings given by the clients and ensure to test the performance as well as their quality at each step of production."
        next="view "
        link="/category/splineshaft/automotive"
        style="col-md-6"
        />

        <MyCategory
        title="AGRICULTURE SPLINE SHAFT"
        content="We offer a broad array of Agricultural Spline Shafts, which is made using high grade steel and manufactured to function efficiently for a longer time than similar shafts available in the market. 
        The quality of this shaft has made us achieve a place of prominence among Agricultural Spline Shaft Manufacturers and Suppliers in India."
        next="view "
        link="/category/splineshaft/agriculture"
        style="col-md-6"
        />

       
 


 

        </div>

           </div>
       
       
</section>
        </Layout>
    )
}

export default SubCategory
